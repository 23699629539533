import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Layout from '@/components/Layout';
import PortfolioHeader from '@/components/Portfolio/PortfolioHeader';
import ContentSection from '@/components/Portfolio/ContentSection';

export default function Harmonielles() {
  const { t } = useTranslation();

  const tags = [
    'data',
    'googleDataStudio',
    'dataViz',
    'python',
    'docker',
    'postgres',
  ];

  return (
    <Layout description={t('description')}>
      <PortfolioHeader
        title={t('title')}
        websites={[t('website')]}
        description={t('description')}
        tags={tags}
      />

      <ContentSection title={t('paragraph1Title')} content={t('paragraph1')} />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["harmonielles", "general"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
